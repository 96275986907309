import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GridStyle from "../components/styles/skeleton/GridStyle"
import JColors from "../components/styles/JColors"
import TypographyStyle from "../components/styles/skeleton/TypographyStyle"
import { css } from "aphrodite"
import { chooseBackground, isDayTime } from "../helpers"
import UtilityStyle from "../components/styles/skeleton/UtilityStyle"
import indexStyle from "../components/styles/index-style"
import TimelineComponent from "../components/background"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={css(GridStyle.Container, UtilityStyle.u_maxHeight)}>
      <div>
        <div>
          <div className={css(JColors.Background_Photo, chooseBackground(), TypographyStyle.h5, JColors.Background_TextClip)}>
            <Link to="/">Jeff Schulthies</Link>
          </div>
          <div style={{fontFamily: 'Not-Roman'}}>
          {isDayTime ?
          <p>
            Welcome to my slice of the internet.<br/> <br/>
            Feel free to contact me.
          </p>
          : <p style={{color: 'white'}}>
              Welcome to my slice of the internet.<br/> <br/>
              Feel free to contact me.
            </p>
          }
            <div>
              <br/>
              <a className={css(indexStyle.NavBar_Link, indexStyle.NavBar_FirstLink, GridStyle.OneColumns)} href="https://www.facebook.com/jeff.schulthies.71"><i className={"fa fa-facebook-official fa-2x"}/></a>
              <a className={css(indexStyle.NavBar_Link, GridStyle.OneColumns)} href="https://twitter.com/CurtisRosseau"><i className={"fa fa-twitter-box fa-2x"}/></a>
              <a className={css(indexStyle.NavBar_Link, GridStyle.OneColumns)} href="https://github.com/jeffschulthies"><i className={"fa fa-github fa-2x"}/></a>
              <a className={css(indexStyle.NavBar_Link, GridStyle.OneColumns)} href="https://www.linkedin.com/in/jeffschulthies"><i className={"fa fa-linkedin-box fa-2x"}/></a>
              <a className={css(indexStyle.NavBar_Link, GridStyle.OneColumns)} href="mailto:jeff@schulthies.haus"><i className={"fa fa-email fa-2x"}/></a>
            </div>
            <TimelineComponent/>

          </div>
        </div>
      </div>
    </div>

    {/*<Link to="/secret/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage
