import { StyleSheet } from 'aphrodite/no-important'

const indexStyle = StyleSheet.create({
	Footer: {
		backgroundColor: "#000000",
		position: 'relative',
		bottom: '0',
		height: '40px',
		width: '100%',
		marginTop: '0px',
		overflowY: 'hidden',
		color: '#FFFFFF'
	},
	NavBar: {
		position: 'absolute',
		right: '0',
		bottom: '0',
	},

	NavBar_FirstLink: {
		//paddingLeft: '-40px',
		marginLeft: '-1%',
	},

	NavBar_Link: {
		fontFamily: 'Not-Roman',
		fontWeight: 'bold',
		fontSize: '1.5rem',
		opacity: '1',
		float: 'left',
		textDecoration: 'none',
		color: 'white',
		marginLeft: '10px',
		marginRight: '10px',
		transition: '.2s',

		':hover': {
			color: '#F7E154',
			transition: '.2s'
		},

		/* Small Mobile Size */
		'@media (min-width: 0px)': {
			fontSize: '1.1rem',
			paddingLeft: '6px',
			paddingRight: '6px',
		},

		/* Larger than mobile */
		'@media (min-width: 400px)': {
			fontSize: '1.2rem',
			paddingLeft: '8px',
			paddingRight: '8px',
			marginRight: '0px',
		},

		/* Larger than phablet (also point when grid becomes active) */
		'@media (min-width: 550px)': {
			fontSize: '1.3rem',
			paddingLeft: '10px',
			paddingRight: '10px',
			marginRight: '20px',
		},

		/* Larger than tablet */
		'@media (min-width: 750px)': {
			fontSize: '1.5rem',
			paddingLeft: '12px',
			paddingRight: '12px',
		},

		/* Larger than desktop */
		'@media (min-width: 1000)': {
			fontSize: '1.5rem',
			paddingLeft: '14px',
			paddingRight: '14px',
		},

		/* Larger than Desktop HD */
		'@media (min-width: 1200)': {
			fontSize: '2.0rem',
			paddingLeft: '16px',
			paddingRight: '16px',
		},

	},
});

export default indexStyle
